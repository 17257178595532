import {createStore} from 'vuex'
import {user, UserState} from './module/user'
import {amwayPay, AmwayPayState} from "@/store/module/amway-pay"
import {occ, OccState} from "@/store/module/occ"
import {delivery, DeliveryState} from "@/store/module/delivery"
import {cart, CartStatus} from "@/store/module/cart"
import {terms, TermsStatus} from "@/store/module/terms"
import {receipt, ReceiptStatus} from "@/store/module/receipt"
import {loadingScreen, LoadingStatus} from "@/store/module/loading"
import {orderResult, OrderResultState} from "@/store/module/order-result"


export interface RootState {
  user: UserState,
  amwayPay: AmwayPayState,
  occ: OccState,
  delivery: DeliveryState,
  cart: CartStatus,
  terms: TermsStatus,
  receipt: ReceiptStatus,
  loadingScreen: LoadingStatus,
  orderResult: OrderResultState
}

export default createStore<RootState>({
  modules: {
    user,
    amwayPay,
    occ,
    delivery,
    cart,
    terms,
    receipt,
    loadingScreen,
    orderResult
  },
})
