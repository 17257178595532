import {Module} from "vuex"
import {RootState} from "@/store"


export interface LoadingStatus {
  display: boolean
}

export const loadingScreen: Module<LoadingStatus, RootState> = {
  namespaced: true,
  state: () => ({
    display: false
  }),
  mutations: {
    on: (state) => {
      state.display = true
    },
    off: (state) => {
      state.display = false
    }
  },
  getters: {
    displayStatue: (state): boolean => {
      return state.display
    }
  }
}