import {Module} from "vuex"
import {RootState} from "@/store"
import {PaymentCaptureResponse} from "@/type/dto"

export interface OrderResultState {
  orderResult: PaymentCaptureResponse | null
}


export const orderResult: Module<OrderResultState, RootState> = {
  namespaced: true,
  state: () => ({
    orderResult: null
  }),
  mutations: {
    updateState: (state, payload: PaymentCaptureResponse) => {
      state.orderResult = payload
    },
    initOrderResult: (state) => {
      state.orderResult = null
    }
  },
  getters: {
    getOrderResult: (state): PaymentCaptureResponse | null => state.orderResult
  },
}
