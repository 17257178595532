import {Module} from "vuex"
import {RootState} from "@/store"
import {Terms} from "@/type/model"

export type TermsStatus = Array<Terms>

export const terms: Module<TermsStatus, RootState> = {
  namespaced: true,
  state: () => ([
      {
        termsIndex: 0,
        termsTitle: "전자상거래법",
        agreed: false
      },
      {
        termsIndex: 1,
        termsTitle: "개인정보 수집 및 이용 동의",
        agreed: false
      },
      {
        termsIndex: 2,
        termsTitle: "개인정보 수집 및 이용 동의", // Toss
        agreed: false
      },
      {
        termsIndex: 3,
        termsTitle: "전자금융거래 기본 약관(이용자용)", // Toss
        agreed: false
      },
      {
        termsIndex: 4,
        termsTitle: "개인정보 제3자 제공 동의", // Toss
        agreed: false
      },
    ]
  ),
  mutations: {
    toggleAgreed: (state, value: Array<boolean>) => {
      for (const index in value) {
        state[index].agreed = value[index]
      }
    }
  },
  getters: {
    getAllTerms: state => state
  }
}
