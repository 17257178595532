import {Module} from "vuex"
import {RootState} from "@/store"
import {client} from "@/client"
import {AxiosResponse} from "axios"
import {OccAuthorization} from "@/type/dto"

const clientId: string = process.env.VUE_APP_OCC_CLIENT_ID
const clientSecret: string = process.env.VUE_APP_OCC_CLIENT_SECRET

export interface OccState {
  accessToken: string
}

export const occ: Module<OccState, RootState> = {
  namespaced: true,
  state: () => ({
    accessToken: ""
  }),
  mutations: {
    updateAccessToken(state, payload: string) {
      state.accessToken = payload
    }
  },
  actions: {
    getOccToken: async (context) => {
      console.log("getOccToken Dispatch called")
      const form = new URLSearchParams()
      form.append("client_id", clientId)
      form.append("client_secret", clientSecret)
      form.append("grant_type", "client_credentials")

      const TOKEN_BASE_URL = process.env.VUE_APP_TOKEN_BASE_URL
      
      const response: AxiosResponse<OccAuthorization> = await client.post(`${TOKEN_BASE_URL}/authorizationserver/oauth/token`,
        form,
        {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Accept": "application/json",
        }
      })

      if (response.status !== 200) {
        throw new Error(`${response.status}`)
      }
      
      context.commit("updateAccessToken", response.data.access_token)
    }
  },
  getters: {
    getAccessToken: (state): string => {
      return state.accessToken
    }
  }
}