import {VpsSearchResponse} from "@/type/dto";
import {AxiosResponse} from "axios";
import {client} from "@/client";
import {generateGetHeader} from "@/api-client/module/headers";

export class ProductClient {
  
  private static instance: ProductClient
  
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}
  
  public static createInstance(): ProductClient {
    if (!ProductClient.instance) {
      ProductClient.instance = new ProductClient()
    }
    
    return ProductClient.instance
  }
  
  async findProductByVps(vps: string, accessToken: string): Promise<VpsSearchResponse> {
    
    const response: AxiosResponse<VpsSearchResponse> = await client.get(
      `kakaowork/products/search/${vps}`,
      {
        headers: generateGetHeader(accessToken)
      }
    )
    
    return response.data
  }
}