import {AuthorizeResponse, TokenExtractResponse} from "@/type/dto"
import {AxiosResponse} from "axios"
import {authorizeClient, lambdaClient} from "@/client"

export class SsoClient {
  
  private static instance: SsoClient
  
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}
  
  public static createInstance(): SsoClient {
    if (!SsoClient.instance) {
      SsoClient.instance = new SsoClient()
    }
    
    return SsoClient.instance
  }
  
  async authorizeWithCookie(): Promise<AuthorizeResponse> {
    
    const response: AxiosResponse<AuthorizeResponse> = await authorizeClient.get("/api/v2/amwaykorea/sso/authorizeEvent")
    
    return response.data
  }
  
  async extractSsoToken(): Promise<TokenExtractResponse> {
  
    const response: AxiosResponse<TokenExtractResponse> = await lambdaClient.get("/enc/encrypt")
    
    return response.data
  }
  
}