import {client} from "@/client"
import store from "@/store"
import {loadBrandPay} from "@tosspayments/brandpay-sdk";
import type {
  HybrisCommonResponse,
  PaymentCaptureRequest,
  PaymentCaptureResponse,
  RegisterReceiptRequest
} from "@/type/dto"
import type {AxiosResponse} from "axios"
import type {PaymentParamsWithoutUrl} from "@tosspayments/brandpay-types/dist/SDKBridge/paymentParams"
import type {RequestPaymentResult} from "@tosspayments/brandpay__types/types/methods/requestPayment"
import {Product} from "@/type/model"
import {generatePostHeader} from "@/api-client/module/headers";


export class OrderClient {
  
  private static instance: OrderClient
  
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}
  
  public static createInstance(): OrderClient {
    if (!OrderClient.instance) {
      OrderClient.instance = new OrderClient()
    }
    
    return OrderClient.instance
  }
  
  async brandPayRequest(
    clientId: string,
    customerId: string,
    tossBrandPayRequest: PaymentParamsWithoutUrl
  ): Promise<{ paymentKey: string; orderId: string; amount: number }> {
    
    const ssoToken = store.getters["user/getSsoToken"]
    
    const redirectUrl = `${process.env.VUE_APP_API_BASE_URL}/amwaypay/access-token/amwaypayaccount?ssoToken=${ssoToken}`
    
    const brandPay = await loadBrandPay(clientId, customerId, {
      redirectUrl: redirectUrl
    })
    
    
    const response: RequestPaymentResult = await brandPay.requestPayment(tossBrandPayRequest)
    
    return {
      paymentKey: response.paymentKey,
      orderId: response.orderId,
      amount: response.amount
    }
  }
  
  
  async registerReceipt(aboId: string, orderCode: string, accessToken: string, registerReceiptRequest: RegisterReceiptRequest): Promise<HybrisCommonResponse> {
    
    const response: AxiosResponse<HybrisCommonResponse> = await client.post(
      `/users/${aboId}/receipt/${orderCode}`,
      registerReceiptRequest,
      {
        headers: generatePostHeader(accessToken)
      }
    )
    
    return response.data
    
    
  }
  async paymentCapture(aboId: string, cartId: string, accessToken: string, paymentCaptureRequest: PaymentCaptureRequest): Promise<PaymentCaptureResponse> {
    const response: AxiosResponse<PaymentCaptureResponse> = await client.post(
      `/users/${aboId}/carts/${cartId}/paymentCaptureAndPlaceOrder?fields=FULL`,
      paymentCaptureRequest,
      {
        headers: generatePostHeader(accessToken)
      }
    )
    
    return response.data
    
  }
  
  generateOrderId(cartId: string): string {
    return this.getDateTime() + cartId.replace("-", "")
  }
  
  generateOrderName(products: Array<Product>): string {
    const others = [...products].splice(1).filter(product => product.vps !== '')
    if (others.length > 0) {
      return `${products[0].name} 외 ${others.length} 건`
    } else {
      return `${products[0].name}`
    }
  }
  
  private getDateTime() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const date = today.getDate();
    const hour = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();
    
    return `${month < 10 ? "0" + month : month}${date < 10 ? "0" + date : date}${hour < 10 ? "0" + hour : hour}${minutes < 10 ? "0" + minutes : minutes}${seconds < 10 ? "0" + seconds : seconds}`
  }
}