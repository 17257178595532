import {AxiosResponse} from "axios";
import {AmwayPayAccountResponse, AmwayPayClientIdResponse} from "@/type/dto";
import {generateGetHeader} from "@/api-client/module/headers";
import {client} from "@/client"

export class AmwayPayClient {
  
  private static instance: AmwayPayClient
  
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}
  
  public static createInstance(): AmwayPayClient {
    if (!AmwayPayClient.instance) {
      AmwayPayClient.instance = new AmwayPayClient()
    }
    
    return AmwayPayClient.instance
  }
  
  async getAmwayPayMethods(aboId: string, accessToken: string): Promise<AmwayPayAccountResponse> {
    
    const response: AxiosResponse<AmwayPayAccountResponse> = await client.get(
      `/users/${aboId}/amwaypay/info`,
      {
        params: {
          party: "ALL",
          method: "ACCOUNT"
        },
        headers: generateGetHeader(accessToken)
      }
    )
    return response.data
  }
  
  async getAmwayPayClientId(accessToken: string): Promise<AmwayPayClientIdResponse> {
    
    const response: AxiosResponse<AmwayPayClientIdResponse> = await client.get(
      `amwaypay/clinet-key?paymentMode=amwaypayaccount`, // client 오타 실수가 아니라 OCC url이 저렇게 되어있는 것임.
      {
        headers: generateGetHeader(accessToken)
      }
    )
    
    return response.data
  }
}