import {Module} from "vuex";
import {RootState} from "@/store";
import {Address} from "@/type/model";
import {AddressResponse, CartValidationResponse} from "@/type/dto";
import {cartClient, deliveryClient} from "@/api-client";


export interface DeliveryState {
  addresses: Array<Address>
  selected: number
  message: string
  cost: number
}


export const delivery: Module<DeliveryState, RootState> = {
  namespaced: true,
  state: () => ({
    addresses: [],
    selected: 0,
    message: "",
    cost: 0
  }),
  mutations: {
    select: (state, index: number) => {
      state.selected = index
    },
    updateAddresses: (state, payload: Array<Address>) => {
      state.addresses = payload
    },
    updateMessage: (state, payload: string) => {
      state.message = payload
    },
    updateCost: (state, payload: number) => {
      state.cost = payload
    }
  },
  getters: {
    getSelectedAddress: (state): Address => {
      return state.addresses[state.selected]
    },
    getAllAddresses: (state): Array<Address> => {
      return state.addresses
    },
    getMessage: (state): string => {
      return state.message
    },
    getCost: (state): number => {
      return state.cost
    }
  },
  actions: {
    getAddresses: async (context) => {
      const aboId = context.rootGetters["user/getAboId"]
      const accessToken = context.rootGetters["occ/getAccessToken"]
      
      try {
        const response: AddressResponse = await deliveryClient.getDeliveryAddresses(aboId, accessToken)
        const addresses = response.addresses
        context.commit("updateAddresses", addresses)
        
      } catch (error) {
        console.error("배송지 조회 에러", error)
        throw error
      }
    },
    syncDeliveryCost: async (context) => {
      const aboId = context.rootGetters["user/getAboId"]
      const cartId = context.rootGetters["cart/getCartId"]
      const accessToken = context.rootGetters["occ/getAccessToken"]

      try {
        const response: CartValidationResponse = await cartClient.validateCart(aboId, cartId, accessToken)
        const totalDeliveryCost: number = response.deliveryCost.value + response.deliveryTax.value
        context.commit("updateCost", totalDeliveryCost)
        
      } catch (error) {
        console.error(error)
        throw error
      }
    }
  }
}