import {Module} from "vuex"
import {RootState} from "@/store"
import {Cart, Product} from "@/type/model"
import {cartClient} from "@/api-client";
import {HybrisCommonResponse} from "@/type/dto";


export interface CartStatus {
  cartId: string
  products: Array<Product>
  cached: Product | null
}

export const cart: Module<CartStatus, RootState> = {
  namespaced: true,
  state: () => ({
    cartId: "",
    products: [],
    cached: null,
  }),
  mutations: {
    setCartId: (state, value: string) => {
      state.cartId = value
    },
    cacheProduct: (state, payload: Product | null) => {
      state.cached = payload
    },
    updateEntryNumber: (state, payload: {index: number, entryNumber: number}) => {
      state.products[payload.index].entryNumber = payload.entryNumber
    },
    addProduct: (state, payload: number) => {
      if (state.cached !== null) {
        state.products[payload] = {...state.cached, readonly: true}
        state.cached = null
        if (payload < state.products.length - 1) {
          state.products[payload + 1].readonly = false
        } else {
          state.products.push(
            {
              vps: '',
              sku: '',
              name: '',
              thumbnail: '',
              price: 0,
              quantity: 0,
              readonly: false,
              entryNumber: -1
            }
          )
        }
      }
    },
    removeProduct: (state, payload: number) => {
      state.products[payload] = {
        vps: '',
        sku: '',
        name: '',
        thumbnail: '',
        price: 0,
        quantity: 0,
        readonly: false,
        entryNumber: -1
      }
      state.products.splice(payload, 1)
  
      if (state.products.length < 3) {
        const readonly = payload === 0 ? state.products[payload + 1].vps === '' :
          state.products[payload - 1].vps === ''
        state.products.push({
          vps: '',
          sku: '',
          name: '',
          thumbnail: '',
          price: 0,
          quantity: 0,
          readonly: readonly,
          entryNumber: -1
        })
      }
    },
    initCart: (state) => {
      if (state.products.length > 0) {
        state.products = []
      }
      
      for (let i = 0; i < 3; i++) {
        state.products.push({
          vps: '',
          sku: '',
          name: '',
          thumbnail: '',
          price: 0,
          quantity: 0,
          readonly: i !== 0,
          entryNumber: -1
        })
      }
    },
  },
  getters: {
    getCart: (state): Cart => {
      return {
        cartId: state.cartId,
        products: state.products,
      }
    },
    getCartId: (state): string => {
      return state.cartId
    },
    getProducts: (state): Array<Product> => {
      return state.products
    },
    getCached: (state): Product | null => {
      return state.cached
    }
  },
  actions: {
    createCart: async (context) => {
      const aboId: string = context.rootGetters["user/getAboId"]
      const accessToken: string = context.rootGetters["occ/getAccessToken"]
      
      const cartId: string = await cartClient.createCart(aboId, accessToken)
      
      context.commit("setCartId", cartId)
    },
    addPaymentMethod: async (context) => {
      const aboId: string = context.rootGetters["user/getAboId"]
      const accessToken: string = context.rootGetters["occ/getAccessToken"]
      
      const response: HybrisCommonResponse = await cartClient.registerPaymentMethod(aboId, context.state.cartId, accessToken)
      console.log(`PaymentMode 등록 => ${response.code}, ${response.returnMessage}`)
      
    }
  }
}