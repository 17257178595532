import {Module} from "vuex";
import {RootState} from "@/store";

export interface UserState {
  aboId: string
  ssoToken: string | null
}

export const user: Module<UserState, RootState> = {
  namespaced: true,
  state: () => ({
    aboId: "",
    ssoToken: null
  }),
  getters: {
    getAboId: (state): string => {
      return state.aboId
    },
    getSsoToken: (state): string | null => {
      return state.ssoToken
    }
  },
  actions: {
  },
  mutations: {
    updateSsoToken: (state, value: string) => {
      state.ssoToken = value
    },
    updateAboId: (state, value: string) => {
      state.aboId = value
    }
  }
}