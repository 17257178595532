import {Module} from "vuex"
import {RootState} from "@/store"
import {Receipt} from "@/type/model"

export interface ReceiptStatus {
  receiptType: string
  receiptIssuanceType: string
  receiptNumberType: string
  receiptIssuanceNumber: string
}

/**
 * receiptType: 거래내역 증빙 유형
 * receiptIssuanceType: 영수증 증빙유형
 *    INCOME_DEDUCTION -> 소득공제
 *    EXPENDITURE_PROOF -> 사업자 지출증빙
 * receiptNumberType: 영수증 번호 유형
 *    CPN -> 휴대폰번호
 *    CDN -> 현금영수증 카드번호
 *    BRN -> 사업자번호
 * receiptIssuanceNumber: 영수증 발행 번호 실제값
 */

export const receipt: Module<ReceiptStatus, RootState> = {
  namespaced: true,
  state: () => ({
    receiptType: "",
    receiptIssuanceType: "",
    receiptNumberType: "",
    receiptIssuanceNumber: "",
  }),
  mutations: {
    updateReceiptInfo: (state, value: Receipt) => {
      state.receiptType = value.receiptType
      state.receiptIssuanceNumber = value.receiptIssuanceNumber
      state.receiptNumberType = value.receiptNumberType
      state.receiptIssuanceType = value.receiptIssuanceType
    },
    initReceipt: (state) => {
      state.receiptType = ''
      state.receiptIssuanceType = ''
      state.receiptNumberType = ''
      state.receiptIssuanceNumber = ''
    },
  },
  getters: {
    getReceiptInfo: (state): Receipt => {
      return {
        receiptType: state.receiptType,
        receiptIssuanceNumber: state.receiptIssuanceNumber,
        receiptNumberType: state.receiptNumberType,
        receiptIssuanceType: state.receiptIssuanceType
      }
    }
  }
}