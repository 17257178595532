import {createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw} from 'vue-router'
import EasyOrder from '../views/order/EasyOrder.vue'
import OrderSheetView from '../views/order/OrderSheetView.vue'
import ErrorPage from '../views/ErrorPage.vue'
import AuthorizationView from '../views/AuthorizationView.vue'
import store from '../store'
import {AuthorizeResponse, TokenExtractResponse} from "@/type/dto"
import {ssoClient} from "@/api-client";


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: {
      path: '/authorization'
    }
  },
  {
    path: '/order-complete',
    name: 'order-complete',
    component: OrderSheetView,
    props: true,
    beforeEnter: async (_to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) => {
      const storedOrderResult = store.getters["orderResult/getOrderResult"]
      if (storedOrderResult === null) {
        return next({
          path: "/easy-order"
        })
      }
      
      store.commit("receipt/initReceipt")
      
      store.commit("loadingScreen/off")
      next()
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: 'error',
      query: {
        'error-code': 'ERROR404'
      }
    },
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorPage,
  },
  {
    path: '/authorization',
    name: 'authorization',
    component: AuthorizationView,
    beforeEnter: async (_to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) => {
      // OCC Access 토큰 발급
      await store.dispatch("occ/getOccToken")
      next()
    }
  },
  {
    path: '/easy-order',
    name: 'easy-order',
    component: EasyOrder,
    beforeEnter: async (_to: RouteLocationNormalized, _from: RouteLocationNormalized, next: NavigationGuardNext) => {
      
      store.commit("loadingScreen/on")
      
      await store.dispatch("occ/getOccToken")
      
      console.log("beforeEnter: /easy-order")
      
      store.commit("cart/initCart")
      
      const environment = process.env.NODE_ENV
      
      // 로컬 환경일 때
      if (environment === "local") {
        // SSO 토큰
        let ssoToken: string | null = store.getters["user/getSsoToken"]
        
        if (ssoToken === null || ssoToken === '') {
          ssoToken = localStorage.getItem("ssoToken")
          store.commit("user/updateSsoToken", ssoToken)
        }
        
        if (ssoToken === null || ssoToken === '') {
          console.error("SSO Token error: SSO Token value => ", `'${ssoToken}'`)
          return next({
            path: '/error',
            query: {
              "error-code": "ERROR401"
            }
          })
        }
        const aboId = atob(ssoToken).split("|")[0]
        store.commit("user/updateAboId", aboId)
      } else { // dev, prod 환경일 때
        // ssoToken으로 하이브리스 인증
        try {
          const authorizeResponse: AuthorizeResponse = await ssoClient.authorizeWithCookie()
    
          const aboId = atob(authorizeResponse.customerUid)
          
          const tokenExtractResponse: TokenExtractResponse = await ssoClient.extractSsoToken()
          const ssoToken = tokenExtractResponse.ssoToken
          
          store.commit("user/updateSsoToken", ssoToken)
          store.commit("user/updateAboId", aboId)
    
        } catch (error) {
          console.error("Hybris authorize error")
          
          return next({
            path: '/error',
            query: {
              "error-code": "ERROR401"
            }
          })
        }
      }
      
      // 배송지 정보 조회
      try {
        await store.dispatch("delivery/getAddresses")
      } catch (error) {
        return next({
          path: '/error',
          query: {
            "error-code": "ERROR_DELIVERY"
          }
        })
      }
      // 암웨이페이 결제수단 정보 조회
      try {
        await store.dispatch("amwayPay/getAmwayPayMethods")
      } catch (error) {
        console.error(error)
        return next({
          path: "error",
          query: {
            "error-code": "ERROR_AMWAY_PAY"
          }
        })
      }
      
      // 암웨이페이 클라이언트아이디
      try {
        await store.dispatch("amwayPay/getAmwayPayClientId")
      } catch (error) {
        console.error(error)
        return next({
          path: "error",
          query: {
            "error-code": "ERROR_AMWAY_PAY"
          }
        })
      }
      
      // 카트 생성
      try {
        await store.dispatch("cart/createCart")
      } catch (error) {
        return next({
          path: "error",
          query: {
            "error-code": "ERROR_CART_CREATE"
          }
        })
      }
      
      // 카트에 결제수단 (암웨이페이 자동이체) 추가
      try {
        await store.dispatch("cart/addPaymentMethod")
      } catch (error) {
        return next({
          path: "error",
          query: {
            "error-code": "ERROR_CART_CREATE"
          }
        })
      }
      
      store.commit("loadingScreen/off")
      next()
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
