<template>
  <div class="layer-content toss">
    <p class="layer-sum">토스페이먼츠(이하 회사라 함)는 전자금융거래 서비스의 제공을 위하여 개인정보가 제3자 제공되는 경우는 이용 서비스 및 결제수단별로 다음과
      같습니다.</p>
    <h2 class="busTxt">1. 개인정보를 제공받는 자, 제공하는 목적 및 항목</h2>
    <ul class="layer-list">
      <li>가. 전자결제지급대행 서비스 제공을 위한 결제정보 제공</li>
    </ul>
    <table class="business_tbData01 top-align">
      <colgroup>
        <col width="auto">
        <col width="38%">
        <col width="38%">
      </colgroup>
      <thead>
      <tr>
        <th>제공목적</th>
        <th>제공받는 자</th>
        <th>제공하는 항목</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>신용카드</td>
        <td>
          [신용카드사] KB카드, 비씨카드, 롯데카드, 삼성카드, NH농협카드, 현대카드, 신한카드, KEB하나카드, 우리카드<br>
          [은행] 경남은행, 광주은행, 국민은행, 기업은행, 농협은행, 대구은행, 부산은행, KDB산업은행, 새마을금고, 수협은행, 신한은행, 신협, 우리은행,
          우체국, 전북은행, 제주은행, KEB하나은행, 씨티은행, SC제일은행, 산림조합중앙회, 케이뱅크, 카카오뱅크<br>
          [VAN사] (㈜코밴, ㈜코세스 ,㈜다우데이타, 퍼스트데이터 코리아, ㈜에스피씨네트웍스, ㈜엑심베이
        </td>
        <td>[거래정보] 카드번호, 고객명, 생년월일, 전화번호<br>
          [비인증결제의 경우 거래정보] 카드번호, 유효기간, 생년월일, 카드 비밀번호 앞 2자리)</td>
      </tr>
      <tr>
        <td>해외카드 결제</td>
        <td>VISA, MASTER, JCB, AMEX, Diners, 유니온페이 인터내셔널</td>
        <td>카드번호, 유효기간, 전화번호, 회원ID, 카드 비밀번호</td>
      </tr>
      <tr>
        <td>간편결제</td>
        <td>㈜비바리퍼블리카, ㈜에스에스지닷컴, 롯데멤버스㈜, 삼성전자㈜, LG전자㈜, 엔에이치엔페이코㈜</td>
        <td>전화번호, 상품명</td>
      </tr>
      <tr>
        <td>신용카드 ARS결제</td>
        <td>㈜엘지유플러스</td>
        <td>카드번호, 전화번호, 유효기간</td>
      </tr>
      <tr>
        <td>계좌이체 결제</td>
        <td>[은행] NH농협, KB은행, 우리은행, 신한은행, 기업은행, 경남은행, 광주은행, 대구은행, 부산은행, KDB산업은행, 새마을금고, 수협은행, 신협,
          우체국, 전북은행, 제주은행, KEB하나은행, 씨티은행, SC제일은행<br>
          [증권사] 유안타, 미래에셋, 삼성, 한국투자, 한화</td>
        <td>계좌번호, 고객명, 생년월일, 보안카드, OTP, (공인인증서 서명 검증 및 신원확인 시) 주민등록번호</td>
      </tr>
      <tr>
        <td>가상계좌 결제</td>
        <td>[은행] 국민은행, 농협은행, 우리은행, 신한은행, KEB하나은행, 기업은행, 우체국, 부산은행, 경남은행, 대구은행, 수협은행</td>
        <td>가상계좌번호</td>
      </tr>
      <tr>
        <td>휴대폰 결제</td>
        <td>[통신사] SK텔레콤㈜, ㈜케이티, ㈜엘지유플러스<br>
          [중개사] ㈜다날, 갤럭시아커뮤니케이션즈㈜, 페이레터</td>
        <td>이통사정보, 휴대폰번호, 생년월일, 성별, IP</td>
      </tr>
      <tr>
        <td>유선전화 결제</td>
        <td>㈜케이티</td>
        <td>전화번호, 생년월일, 성별</td>
      </tr>
      <tr>
        <td>상품권 결제</td>
        <td>㈜한국문화진흥, 티알엔</td>
        <td>‘구매자ID, 비밀번호’(또는 상품권번호)</td>
      </tr>
      <tr>
        <td>자체 간편결제 (신용카드)</td>
        <td>[신용카드사] 신한카드, KB카드, 하나카드, BC카드, 삼성카드, NH농협카드, 현대카드, 롯데카드, 우리카드, 씨티카드</td>
        <td>CI, 생년월일, 성별, 단말기 고유번호, 카드번호, 카드 CVC, 카드 유효기간, 카드 비밀번호</td>
      </tr>
      <tr>
        <td>자체 간편결제 (계좌이체)</td>
        <td>금융결제원, [은행] NH농협은행, 농협중앙회, KB은행, KDB산업은행, 우리은행, 신한은행, 기업은행, 경남은행, 광주은행, 대구은행, 부산은행,
          새마을금고, 수협, 수협중앙회, 신협, 우체국, 전북은행, 제주은행, KEB하나은행, 씨티은행, SC제일은행, 카카오뱅크, K뱅크, 산림조합중앙회
          [증권사] 교보증권, 미래에셋대우, 삼성증권, 신한금융투자, 유진투자증권, 이베스트투자증권, 키움증권, 하이투자증권, 한국투자증권, 한화투자증권,
          현대차증권, KB증권, NH투자증권, SK증권, 메리츠증권, 대신증권, DB금융투자</td>
        <td>휴대전화번호, 이름, 계좌번호, 계좌 비밀번호, 주민번호</td>
      </tr>
      <tr>
        <td>글로벌서비스</td>
        <td>알리페이</td>
        <td>전화번호, 회원ID, 비밀번호</td>
      </tr>
      <tr>
        <td>현금영수증서비스</td>
        <td>국세청, 제이티넷</td>
        <td>[현금영수증 발급정보] 주민등록번호, 사업자번호, 휴대폰번호, 현금영수증 등록카드번호</td>
      </tr>
      <tr>
        <td>신용카드 유효성 검증 서비스</td>
        <td>[신용카드사] KB카드, 비씨카드, 롯데카드, 삼성카드, NH농협카드, 현대카드, 신한카드, KEB하나카드, 우리카드</td>
        <td>카드번호, 고객명, 생년월일, 전화번호<br>
          ※ BC카드는 IP주소 포함</td>
      </tr>
      <tr>
        <td>계좌 유효성확인 서비스</td>
        <td>[은행] 경남은행, 광주은행, 국민은행, 기업은행, 농협은행, 대구은행, 부산은행, KDB산업은행, 새마을금고, 수협, 신한은행, 신협, 외환은행,
          우리은행, 우체국, 전북은행, 제주은행, 하나은행, 씨티은행, SC제일은행</td>
        <td>계좌번호, 고객명, 생년월일</td>
      </tr>
      <tr>
        <td>휴대폰 본인확인 서비스</td>
        <td>㈜케이티, ㈜엘지유플러스, KMC㈜</td>
        <td>성명, 생년월일, 성별, 휴대폰번호, 내외국인 식별정보, CI, DI</td>
      </tr>
      <tr>
        <td>오픈뱅킹 서비스(출금이체, 입금이체, 잔액조회) 제공</td>
        <td>금융결제원</td>
        <td>이름, 생년월일, 휴대전화번호, 금융기관명 및 계좌번호, 암호화된 동일인 식별정보(CI), 이메일</td>
      </tr>
      <tr>
        <td>관련 법령에 따른 의무 이행</td>
        <td>금융정보분석원, 국세청</td>
        <td>실명번호, 거래목적, 자금원천</td>
      </tr>
      </tbody>
    </table>
    <span class="person_txt">
      * 회사는 개인정보를 위의 목적으로 제3자 제공하며, 동의받은 목적 이외의 제3자에게 제공하지 처리하지 않습니다.
    </span>
    
    <h2 class="busTxt">2. 개인정보의 보유 및 이용기간</h2>
    <p class="layer-sum">
      개인정보는 원칙적으로 개인정보의 수집·이용 목적이 달성되면 지체없이 파기합니다. 단, 다른 법령에 특별한 규정이 있는 경우 관련 법령에서 정하는 기간동안 보유합니다.
    </p>
    
    <h2 class="busTxt">3. 이용자는 회사의 개인정보 제3자 제공에 대한 동의를 거부할 권리가 있습니다.</h2>
    <p class="layer-sum">
      다만, 개인정보의 제3자 제공 동의를 거부할 경우 전자결제지급대행 서비스의 이용이 제한될 수 있음을 알려드립니다.
    </p>
  </div>

</template>

<script setup lang="ts">
</script>

<style scoped>
</style>