<template>
  <div id="quick">
    <div class="quick-links">
      <div class="container">
        <div class="row footer-quick footer-quick--change">
          <ul id="accordionQuick" class="qlink-left-box">
            <li class="quick-links-item col-sm-3 panel">
              <p class="quick-links-header">암웨이 소식</p>
              <ul id="quick-links-collapse1" class="panel-collapse collapse">
                <li><a href="#">공지사항</a></li>
                <li><a href="#">뉴스룸</a></li>
                <li><a href="#">신제품</a></li>
                <li><a href="#">프로모션</a></li>
                <li><a href="#">일시품절/해지/단종</a></li>
                <li><a href="#">사회공헌 소식</a></li>
              </ul>
            </li>
            <li class="quick-links-item col-sm-3 panel">
              <p class="quick-links-header akl-foot mob1"><a href="#">고객 서비스</a></p>
            </li>
            <li class="quick-links-item col-sm-3 panel">
              <p class="quick-links-header akl-foot mob2"><a href="#">암웨이 비즈니스 &amp; 브랜드 센터 찾기</a></p>
            </li>
            <li class="quick-links-item col-sm-3 panel">
              <p class="quick-links-header akl-foot mob3"><a href="#">ABO 윤리강령 및 행동지침</a></p>
            </li>
          </ul>
          <div class="quick-links-misc qlink-right-box">
            <div class="quick-links-sns">
              <p class="quick-links-title"> SNS를 통해 암웨이를 만나보세요!</p>
              <div class="foot-social-icon">
                <a href="#" class="fbook">facebook</a>
                <a href="#" class="istar">instgram</a>
              </div>
            </div>
            <section class="footerShotcut mob">
              <h2 class="hidden">바로가기</h2>
              <div class="jump-dropdown-container">
                <select onchange="ACC.common.openExternalBrowserForHtmlObject(this.options[this.selectedIndex])" class="form-control" title="관련 사이트 새창으로 이동">
                  <option value="" selected="selected">암웨이 관련사이트 바로가기</option>
                  <option value="https://www.amwaysupplier.co.kr/" data-target="NEWWINDOW" data-external="true">협력사 포탈</option>
                  <option value="https://www.macco.or.kr/ko/main.action" data-target="NEWWINDOW" data-external="true">직접판매공제조합</option>
                  <option value="http://www.amwayon.co.kr" data-target="NEWWINDOW" data-external="true">암웨이 온</option>
                  <option value="http://www.amwayfoundation.or.kr/" data-target="NEWWINDOW" data-external="false">Amway 미래재단</option>
                </select>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped>

</style>