import axios, {AxiosInstance, AxiosRequestConfig} from "axios";


const API_HOST: string = process.env.VUE_APP_API_BASE_URL
const HYBRIS_URL: string = process.env.VUE_APP_HYBRIS_URL

const clientConfig: AxiosRequestConfig = {
  baseURL: API_HOST,
  timeout: 10000
}

const client: AxiosInstance = axios.create(clientConfig)

client.interceptors.request.use(
  (config) => {
    console.log(config)
    return config
  },
  (error) => {
    return Promise.reject(error)
  })


const authorizeClientConfig: AxiosRequestConfig = {
  baseURL: HYBRIS_URL,
  timeout: 1000,
  withCredentials: true,
  headers: {
    "Accept": "application/json"
  }
}

const authorizeClient: AxiosInstance = axios.create(authorizeClientConfig)

authorizeClient.interceptors.request.use(
  (config) => {
    console.log(config)
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

const lambdaClientConfig: AxiosRequestConfig = {
  timeout: 5000,
  headers: {
    "Accept": "application/json"
  }
}

const lambdaClient: AxiosInstance = axios.create(lambdaClientConfig)

lambdaClient.interceptors.request.use(
  (config) => {
    console.log(config)
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export { client, authorizeClient, lambdaClient }