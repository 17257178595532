import {generateGetHeader, generatePostHeader} from "@/api-client/module/headers"
import {client} from "@/client"
import {
  CartEntryRequest,
  CartEntryResponse,
  CartSearchResponse,
  CartValidationResponse,
  HybrisCommonResponse
} from "@/type/dto"
import {AxiosResponse} from "axios"

export class CartClient {
  
  private static instance: CartClient
  
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}
  
  public static createInstance(): CartClient {
    if (!CartClient.instance) {
      CartClient.instance = new CartClient()
    }
    
    return CartClient.instance
  }
  
  async createCart(aboId: string, accessToken: string): Promise<string> {
    const response: AxiosResponse<{code: string; [key: string]: any}> = await client.post(
      `/accounts/${aboId}/users/${aboId}/carts`,
      { cartType: "WEB" },
      {
        headers: generatePostHeader(accessToken)
      }
    )
    
    return response.data.code
  }
  
  async registerPaymentMethod(aboId: string, cartId: string, accessToken: string): Promise<HybrisCommonResponse> {
    
    const response: AxiosResponse<HybrisCommonResponse> = await client.post(
      `/users/${aboId}/carts/${cartId}/paymentmode?paymentModeCode=amwaypayaccount`,
      {},
      {
        headers: generatePostHeader(accessToken)
      }
    )
    
    return response.data
  }
  
  async addProductsToCart(aboId: string, cartId: string, accessToken: string, cartEntryRequest: CartEntryRequest): Promise<CartEntryResponse> {
    const response: AxiosResponse<CartEntryResponse> = await client.post(
      `/users/${aboId}/carts/${cartId}/entries?fields=FULL`,
      cartEntryRequest,
      {
        headers: generatePostHeader(accessToken)
      }
    )
    return response.data
  }
  
  async emptyCart(aboId: string, cartId: string, accessToken: string, entryNumber: number): Promise<boolean> {
    await client.delete(`/users/${aboId}/carts/${cartId}/entries/${entryNumber}`, {
      headers: generateGetHeader(accessToken)
    })
    return true
  }
  
  async validateCart(aboId: string, cartId: string, accessToken: string): Promise<CartValidationResponse> {
    const response: AxiosResponse<CartValidationResponse> = await client.post(
      `/users/${aboId}/carts/${cartId}/validateCart?fields=FULL`,
      {},
      {
        headers: generatePostHeader(accessToken)
      }
    )
    return response.data
    
  }
  
  async searchCart(aboId: string, cartId: string, accessToken: string): Promise<CartSearchResponse> {
    const response: AxiosResponse<CartSearchResponse> = await client.get(
      `/users/${aboId}/carts/${cartId}?fields=FULL`,
      {
        headers: generateGetHeader(accessToken)
      }
    )
    
    return response.data
  }
}