<template>
  <div class="layer-content toss">
    <p class="layer-sum">
      토스페이먼츠(이하 회사라 함)는 "신용정보의 이용 및 보호에 관한 법률", "전자금융거래법", "전자상거래 등에서의 소비자보호에 관한 법률", "정보통신망 이용촉진 및
      정보보호 등에 관한 법률" 및 "개인정보 보호법" 등 관련 법령을 준수하여, 이용자 권익 보호에 최선을 다하고 있습니다.
    </p>
    <h2 class="busTxt">1. 개인정보 수집·이용 목적</h2>
    <ul class="layer-list">
      <li>
        가. 전자금융거래 서비스 제공: 전자결제지급대행, 자체 간편결제 및 오픈뱅킹 등
        <ul class="layer-inner-list">
          <li>결제수단(신용카드 등)을 이용한 전자결제 서비스 제공</li>
          <li>전자결제 결과 조회 및 통보</li>
          <li>세금계산서 및 현금영수증 발행</li>
          <li>결제한 거래의 취소 또는 환불, 상품 배송 등 전자상거래 관련 서비스 제공</li>
        </ul>
      </li>
      <li>
        나. 서비스 이용자 관리 및 민원대응
        <ul class="layer-inner-list">
          <li>이용자 본인확인, 개인식별, 공지사항의 전달, 고객 불만 및 민원처리 등</li>
          <li>금융사고 조사, 분쟁해결, 민원처리, 법령상 의무이행</li>
          <li>새로운 정보와 고지사항의 안내</li>
        </ul>
      </li>
    </ul>
    <span class="person_txt">
      * 회사는 개인정보를 위의 목적으로 처리하며, 동의받은 목적 이외의 용도로 처리하지 않습니다.
    </span>
    
    <h2 class="busTxt">2. 수집·이용하는 개인정보 항목</h2>
    <ul class="layer-list">
      <li>가. 전자결제 시 수집되는 항목
        <ul class="layer-inner-list">
          <li>구매자명 또는 구매자ID, 결제금액</li>
        </ul>
      </li>
      <li>나. 결제수단별 수집되는 항목
        <table class="business_tbData01">
          <colgroup>
            <col width="30%">
            <col width="auto">
          </colgroup>
          <thead>
          <tr>
            <th>결제수단</th>
            <th>개인정보 항목</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th>신용카드</th>
            <td>카드번호, 카드 유효기간, 카드 비밀번호, 카드 CVC, 생년월일 등 (수기결제 시 수집항목을 구분하여 진행함)</td>
          </tr>
          <tr>
            <th>가상계좌</th>
            <td>은행, 가상계좌번호, 입금자명</td>
          </tr>
          <tr>
            <th>계좌이체</th>
            <td>은행, 출금계좌번호, 비밀번호, 주민등록번호(또는 사업자등록번호), 예금주</td>
          </tr>
          <tr>
            <th>휴대폰</th>
            <td>통신사, 휴대폰번호, 주민등록번호 앞 7자리(생년월일, 성별)</td>
          </tr>
          <tr>
            <th>상품권</th>
            <td>상품권 발행사 아이디, 비밀번호’ 또는 상품권 번호</td>
          </tr>
          <tr>
            <th>현금영수증 발행</th>
            <td>
              ‘주민등록번호, 휴대폰번호, 현금영수증번호, 기타 카드번호’ 중 현금영수증 발급정보<br>
              [참고] 가상계좌, 계좌이체 시 현금영수증 발행 가능
            </td>
          </tr>
          </tbody>
        </table>
      </li>
      <li>다. 전자결제 내역 발신을 위한 정보
        <ul class="layer-inner-list">
          <li>휴대폰번호, 이메일</li>
        </ul>
      </li>
      <li>라. 자체 간편결제 이용자 정보
        <ul class="layer-inner-list">
          <li>CI, 휴대폰번호, 통신사, 생년월일, 성별, 이름</li>
        </ul>
      </li>
    </ul>
    
    <h2 class="busTxt">3. 개인정보의 보유 및 이용기간</h2>
    <p class="layer-sum">
      원칙적으로 개인정보의 수집·이용 목적이 달성되면 지체 없이 파기합니다. 단, 관련 법령의 규정 또는 회사 내부 방침에 의하여 보존할 필요가 있는 경우 아래에서 정한 기간
      동안 개인정보를 보유합니다.
    </p>
    <table class="business_tbData01 top-align">
      <colgroup>
        <col width="42%">
        <col width="42%">
        <col width="auto">
      </colgroup>
      <thead>
      <tr>
        <th>구분</th>
        <th>보존 근거</th>
        <th>보존기간</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>부정 이용 방지를 위한 결제 이용에 관한 기록</td>
        <td>부정 이용 방지 및 탐지</td>
        <td>5년</td>
      </tr>
      <tr>
        <td>휴대폰 본인확인 서비스 이용에 관한 기록</td>
        <td>정보통신 이용촉진 및 정보보호 등에 관한 법률</td>
        <td>6개월</td>
      </tr>
      <tr>
        <td>계약 또는 청약철회 등에 관한 기록</td>
        <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
        <td>5년</td>
      </tr>
      <tr>
        <td>1만원 이상 대금결제 및 재화 등의 공급에 관한 기록</td>
        <td>전자상거래 등에서의 소비자보호에 관한 법률 </td>
        <td>5년</td>
      </tr>
      <tr>
        <td>1만원 이상 대금결제 및 재화 등의 공급에 관한 기록</td>
        <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
        <td>5년</td>
      </tr>
      <tr>
        <td>1만원 미만 대금결제 및 재화 등의 공급에 관한 기록</td>
        <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
        <td>1년</td>
      </tr>
      <tr>
        <td>웹사이트 방문 또는 자체 간편결제 접속에 관한 기록</td>
        <td>통신비밀보호법에 관한 법률</td>
        <td>3개월</td>
      </tr>
      </tbody>
    </table>
    
    <h2 class="busTxt">4. 이용자는 회사의 개인정보 수집·이용 동의를 거부할 권리가 있습니다.</h2>
    <p class="layer-sum">다만, 개인정보의 수집·이용 동의를 거부할 경우 전자결제 서비스의 이용이 제한될 수 있음을 알려드립니다.</p>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped>

</style>