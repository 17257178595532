import {AxiosRequestHeaders} from "axios";

export function generateGetHeader(accessToken: string): AxiosRequestHeaders {
  return {
    "Accept": "application/json;charset=UTF-8",
    "Authorization": `Bearer ${accessToken}`
  }
}

export function generatePostHeader(accessToken: string): AxiosRequestHeaders {
  return {
    ...generateGetHeader(accessToken),
    "Content-Type": "application/json;charset=UTF-8"
  }
}