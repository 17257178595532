import {AddressResponse, HybrisCommonResponse, RegisterDeliveryRequest} from "@/type/dto"
import {AxiosResponse} from "axios"
import {client} from "@/client"
import {generateGetHeader, generatePostHeader} from "@/api-client/module/headers"

export class DeliveryClient {
  
  private static instance: DeliveryClient
  
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}
  
  public static createInstance(): DeliveryClient {
    if (!DeliveryClient.instance) {
      DeliveryClient.instance = new DeliveryClient()
    }
    
    return DeliveryClient.instance
  }
  
  async getDeliveryAddresses(aboId: string, accessToken: string): Promise<AddressResponse> {
  
    const url = `/kakaowork/addresses/${aboId}`
    
    const response: AxiosResponse<AddressResponse> = await client.get(url, {
      headers: generateGetHeader(accessToken)
    })
    
    return response.data
  }
  
  async registerDeliveryAddress(aboId: string, cartId: string, accessToken: string, registerDeliveryRequest: RegisterDeliveryRequest): Promise<HybrisCommonResponse> {
    const response: AxiosResponse<HybrisCommonResponse> = await client.put(
      `/users/${aboId}/carts/${cartId}/delivery-address`,
      registerDeliveryRequest,
      {
        headers: generatePostHeader(accessToken)
      }
    )
    
    return response.data
    
  }
}