import {Module} from "vuex"
import {RootState} from "@/store"
import {AmwayPayAccount} from '@/type/model'
import {AmwayPayAccountResponse, AmwayPayClientIdResponse} from "@/type/dto"
import {amwayPayClient} from "@/api-client"

export interface AmwayPayState {
  storeId: string
  clientId: string
  paymentMethods: Array<AmwayPayAccount>
  selected: number | null
}


export const amwayPay: Module<AmwayPayState, RootState> = {
  namespaced: true,
  state: () => ({
    storeId: "",
    clientId: "",
    paymentMethods: [],
    selected: null
  }),
  getters: {
    getStoreId: (state): string => {
      console.log("GetStoreId Called")
      return state.storeId
    },
    getAllMethods: (state): Array<AmwayPayAccount> => {
      return state.paymentMethods
    },
    getSelectedMethod: (state): AmwayPayAccount | null => {
      return state.selected !== null ? state.paymentMethods[state.selected] : null
    },
    getClientId: (state): string => state.clientId
  },
  actions: {
    getAmwayPayMethods: async (context) => {
      const aboId: string = context.rootGetters["user/getAboId"]
      const accessToken: string = context.rootGetters["occ/getAccessToken"]
      
      const response: AmwayPayAccountResponse = await amwayPayClient.getAmwayPayMethods(aboId, accessToken)

      const methods = response.accounts.masterParty
      const storeId = response.accounts.storeId

      if (methods.length > 0) {
        context.commit("updateMethods", methods)
        context.commit("updateStoreId", storeId)
      }

    },
    getAmwayPayClientId: async (context) => {

      const accessToken: string = context.rootGetters["occ/getAccessToken"]
      const response: AmwayPayClientIdResponse = await amwayPayClient.getAmwayPayClientId(accessToken)
      context.commit("updateClientId", response.amwayPayAccount.clientKey)

    }
  },
  mutations: {
    select: (state, index: number) => {
      state.selected = index
    },
    updateMethods: (state, payload: Array<AmwayPayAccount>) => {
      state.paymentMethods = payload
    },
    updateStoreId: (state, payload: string) => {
      state.storeId = payload
    },
    updateClientId: (state, payload: string) => {
      state.clientId = payload
    }
  }
}