import {OrderClient} from "@/api-client/module/order"
import {AmwayPayClient} from "@/api-client/module/amway-pay"
import {CartClient} from "@/api-client/module/cart"
import {SsoClient} from "@/api-client/module/authorize"
import {DeliveryClient} from "@/api-client/module/delivery"
import {ProductClient} from "@/api-client/module/product"

export const orderClient = OrderClient.createInstance()
export const amwayPayClient = AmwayPayClient.createInstance()
export const cartClient = CartClient.createInstance()
export const ssoClient = SsoClient.createInstance()
export const deliveryClient = DeliveryClient.createInstance()
export const productClient = ProductClient.createInstance()