<template>
  <h2 class="top_desc">
    간편 주문을 위해 제품번호(VPS)와<br>
    수량을 입력해주세요.
  </h2>
</template>

<script setup type="ts">
import {onMounted} from "vue";

onMounted(() => {
  console.log("TitleComponent has been mounted")
})

</script>
